import { useContext } from 'react';
import Head from 'next/head';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { ThemeContext } from '@forma/forma-ui-kit';
import MainBanner from '@/components/blocks/MainBanner';
import VideoBanner from '@/components/blocks/VideoBanner';
import HomepageTitle from '@/components/blocks/HomepageTitle';
import AdvantagesBanner from '@/components/blocks/AdvantagesBanner';
import { HowToUseBanner, HowToUseBannerMobile } from '@/components/blocks/HowToUseBanner';
import Counts from '@/components/blocks/Counts';
import EconomyCalc from '@/components/blocks/EconomyCalc';
import TemplatesBanner from '@/components/blocks/TemplatesBanner';
import ApplicationsList from '@/components/blocks/ApplicationsList';
import PromoBanner from '@/components/blocks/PromoBanner';
import Questions from '@/components/blocks/Questions';
import MetaTags from '@/components/MetaTags';
import useMetaTags from '@/hooks/useMetaTags';
import { queries } from '@/helpers/api';
import getHeadersFromServer from '@/helpers/getHeadersFromServer';

export const getServerSideProps = async ({ req, res, params }) => {
  try {
    res.setHeader(
      'Cache-Control',
      'public, s-maxage=14400, stale-while-revalidate=10'
    );
    const mainData = await queries.getMainData(params.locale);
    const homeData = await queries.getPageData(params.locale, 'home');
    const headersParse = await getHeadersFromServer(req);
    return {
      props: {
        initialState: {
          main: mainData,
        },
        home: homeData,
        headersParse,
        ...(await serverSideTranslations(params.locale, ['common', 'uikit'])),
      },
    }
  } catch {
    return {
      notFound: true
    }
  }
};

const Home = ({ home }) => {
  const { t } = useTranslation();

  const metaData = {
    title: t('main_title'),
    description: t('main_description'),
    keywords: t('main_keywords'),
  };
  const { viewport, lang } = useContext(ThemeContext);
  useMetaTags({ lang, ...metaData });

  if (!home) return null;

  const { main_banner, video_slider, advantages, how_to_use, counts, templates, applications, questions } = home;
  const isPhone = viewport === 'phone';

  return (
    <div>
      <Head>
        <title>{metaData.title}</title>
        <MetaTags {...metaData} />
      </Head>

      <MainBanner {...main_banner} />
      {!isPhone && <VideoBanner items={video_slider} />}
      {!isPhone && <HomepageTitle />}
      <AdvantagesBanner items={advantages} />
      {isPhone ? <HowToUseBannerMobile items={how_to_use} /> : <HowToUseBanner items={how_to_use} />}
      <Counts items={counts} />
      <EconomyCalc />
      <TemplatesBanner items={templates} />
      <ApplicationsList items={applications} />
      <PromoBanner />
      <Questions items={questions} />
    </div>
  );
};

export default Home;
